import React from 'react';
import Layout from 'components/Layout';
import styled from 'styled-components';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
    HeaderContainer,
    RelativeWrapperWithImage,
    MainSection,
} from 'components/pages/Integrations/styled';
import { WidthWrapper } from 'components/wrappers';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { apiDocsLinks, apiDocsLinksChinese, apiDocsLinksKorean, apiDocsLinksJapanese } from 'components/Layout/navigation';
import Helmet from 'react-helmet';
import Joe from 'images/our-team/joe-zhang.png'
import { HeaderMedium, BaseTransparentP } from 'components/typography'; 
import ArrowButton from 'components/Button/ArrowButton';

const Wrapper = styled.div`
    padding-top: 100px;
    text-align: center;
    .column {
      float: left;
      width: 45%;
      text-align: left;
    }

    h1 {
      font-size: 45px; 
      font-weight: 500;
    }

    .column-second img {
      margin: 0 auto;
    }
    
    ${BaseTransparentP} {
        margin-bottom: 50px;
    }
`;

const Row = styled.div`
    max-width:1300px;

`;


const HeroSocial = () => {
    const intl = useIntl()

    return (
        <WidthWrapper whiteMobile>
           <Wrapper>
                <div className="row">
                <div className="column">
                    <h1>{intl.formatMessage({ id: "social-header" })}</h1>
                    <BaseTransparentP>
                        {intl.formatMessage({ id: "social-text" })}
                    </BaseTransparentP>
                    <ArrowButton>{intl.formatMessage({ id: "home-hero-button" })}</ArrowButton>
                </div>
                <div className="column-second">
                    <img src={Joe} alt="Joe Zhang"/>
                </div>
                </div>
            </Wrapper>
        </WidthWrapper>
    );
};

export default HeroSocial;
