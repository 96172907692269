import React from 'react';
import Layout from 'components/Layout';
import styled from 'styled-components';
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl";
import {
    HeaderContainer,
    RelativeWrapperWithImage,
    MainSection,
} from 'components/pages/Integrations/styled';
import { WidthWrapper } from 'components/wrappers';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';
import { apiDocsLinks, apiDocsLinksChinese, apiDocsLinksKorean, apiDocsLinksJapanese } from 'components/Layout/navigation';
import Helmet from 'react-helmet';
import Joe from 'images/our-team/joe-zhang.png'
import HeroSocial from './hero.tsx';
import { HeaderMedium, BaseTransparentP } from 'components/typography'; 
import WhyUs from './why-us';
import OurPartners from './our-partner';

const SocialMedia = () => {
    const intl = useIntl()

    return (
        <Layout>
            <Helmet>
                <title>Social Media</title>
            </Helmet>
            <RelativeWrapperWithImage>
                <WidthWrapper whiteMobile>
                    <HeroSocial />
                    <WhyUs />
                    <OurPartners />
                </WidthWrapper>
            </RelativeWrapperWithImage>
        </Layout>
    );
};

export default SocialMedia;
